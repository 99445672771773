<template>
  <v-container fluid class="grey lighten-4">
    <v-row dense>
      <v-col cols="12" class="title d-flex align-center">
        URL:
        <v-btn
          color="color3"
          text
          :href="`https://${username}.volleyballlife.com`"
          target="_blank"
          class="btnLink"
        >https://{{username}}.volleyballlife.com</v-btn>
      </v-col>
      <v-col>
        This is your custom url. This url will only show your events.
        Your events will still show on our national list, but if you prefer you can
        send your players to your the above url to see your events with your branding.
      </v-col>
      <v-col cols="12">
        <v-expansion-panels hover v-model="panel" multiple>
          <!-- THEME -->
          <v-expansion-panel>
            <v-expansion-panel-header color="color1 color1Text--text" class="title">
              Theme Editor
              <template v-slot:actions>
                <v-icon color="color1Text">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <theme-editor :username="username"></theme-editor>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- PAGES -->
          <v-expansion-panel>
            <v-expansion-panel-header color="color1 color1Text--text" class="title bt">
              Pages Editor
              <template v-slot:actions>
                <v-icon color="color1Text">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <page-editor :username="username"></page-editor>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ThemeEditor from '@/components/Organization/ThemeEditor.vue'
import PageEditor from '@/components/Organization/PageEditor.vue'

export default {
  props: ['username'],
  data () {
    return {
      panel: []
    }
  },
  components: {
    ThemeEditor,
    PageEditor
  }
}
</script>

<style scoped>
.btnLink {
  text-transform: none !important;
}
.bt {
  border-top: 1px solid white !important
}
>>> .v-expansion-panel__header {
  margin-bottom: 2px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important;
}
</style>
